import { render, staticRenderFns } from "./ActiveBadge.vue?vue&type=template&id=14f5a4a4&scoped=true"
import script from "./ActiveBadge.vue?vue&type=script&setup=true&lang=js"
export * from "./ActiveBadge.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ActiveBadge.vue?vue&type=style&index=0&id=14f5a4a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14f5a4a4",
  null
  
)

export default component.exports